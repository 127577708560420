import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PostCard from '../components/PostCard'

export const query = graphql`
  query($slug: String!) {
    posts: allStrapiPost(
      sort: { fields: created_on, order: DESC }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        title
        slug
        excerpt
        created_on
        author {
          name
          username
        }
        tags {
          title
          slug
        }
      }
    }

    tag: strapiTag(slug: { eq: $slug }) {
      title
    }
  }
`

const PostTempalte = ({ data }) => {
  return (
    <Layout>
      <SEO title={`Posts tagged ${data.tag.title}`} />
      <div className="container">
        <h2 class="text-3xl font-bold text-gray-800 dark:text-gray-100 mb-5">
          Posts Tagged: <i>{data.tag.title}</i>
        </h2>
      </div>
      {data &&
        data.posts.nodes.map(post => <PostCard key={post.slug} post={post} />)}
    </Layout>
  )
}

export default PostTempalte
